import axios from "axios";
import { useState } from "react";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Tooltip from "@mui/material/Tooltip";

import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import RejectIcon from "@mui/icons-material/Block";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ConflictIcon from "@mui/icons-material/ErrorOutline";
import RestoreIcon from "@mui/icons-material/Restore";
import SendIcon from "@mui/icons-material/Send";
import AdvanceIcon from "@mui/icons-material/ThumbUp";
import UploadIcon from "@mui/icons-material/Upload";
import ValidateIcon from "@mui/icons-material/Verified";

import WarningIcon from "@mui/icons-material/Warning";

import { API_BASE } from "@/App";
import { fromNow } from "@/utils";
import { Typography } from "@mui/material";

export default function EventButtonsContainer({
  target,
  searchData,
  setDisplay,
  setTargets,
  targets,
  fetchTargetCount,
  // enrichTarget,
}) {
  const [error, setError] = useState(null);
  const [conflictAnchorEl, setConflictAnchorEl] = useState(null);
  const [restoreAnchorEl, setRestoreAnchorEl] = useState(null);
  const [moreAnchorEl, setMoreAnchorEl] = useState(null);

  const targetStage = target["stage"];

  const postEvent = async (type) => {
    // setDisplay("none")
    setTargets(targets.filter((t) => t.domain != target["domain"]));
    setConflictAnchorEl(null);
    setRestoreAnchorEl(null);
    // handleTargetCount(target["stage"], type);
    try {
      // const endpoint = ;
      const eventResponse = await axios.post(API_BASE + "transition_event", {
        search_uid: searchData["uid"],
        domain: target["domain"],
        type: type,
        data: {
          id: target["id"],
        },
        actor_key: localStorage.getItem("actorKey"),
        // token: localStorage.getItem("accessToken"),
      });
      // getTargetCount()
      // processEvents() // uhh do I need this?
      console.log("Processed Events");

      fetchTargetCount();
    } catch (error) {
      console.log(error);
      setError(error.message);
      setTargets([...targets, target]);
      setError(error.message)
    }
  };

  // conflict menu stuff

  return (
    <Box id="button-group-container" style={{ marginTop: "10px" }}>
      {error && <WarningIcon color="error" />}

      <Typography variant="caption" sx={{ opacity: 0.5, mr: 2 }}>
        {fromNow(target["updated"])}
      </Typography>
      {/* <IconButton sx={{ mx: 2 }} color="auto" onClick={enrichTarget}>
        <AutoAwesome />
      </IconButton> */}

      {["reject", "client_reject", "conflict", "client_conflict"].includes(
        targetStage
      ) && (
        <>
          <Button
            id="open-restore-menu"
            startIcon={<RestoreIcon />}
            onClick={(event) => setRestoreAnchorEl(event.currentTarget)}
            color="info"
          ></Button>
          <Menu
            id="restore-menu"
            anchorEl={restoreAnchorEl}
            open={Boolean(restoreAnchorEl)}
            onClose={() => setRestoreAnchorEl(null)}
            MenuListProps={{
              "aria-labelledby": "basic-button",
            }}
          >
            <MenuItem value="" disabled style={{ textTransform: "uppercase" }}>
              Restore Target
            </MenuItem>
            <MenuItem
              key="conflict"
              color="info"
              onClick={(e) => postEvent("validate")}
              style={{ textTransform: "uppercase" }}
            >
              Validated
            </MenuItem>
            <MenuItem
              key="conflict"
              color="info"
              onClick={(e) => postEvent("client_approve")}
              style={{ textTransform: "uppercase" }}
            >
              Client Approved
            </MenuItem>
          </Menu>
        </>
      )}

      <span>
        <Button
          startIcon={<ConflictIcon />}
          onClick={(event) => setConflictAnchorEl(event.currentTarget)}
          color="info"
        ></Button>
        <Menu
          id="conflict-menu"
          anchorEl={conflictAnchorEl}
          open={Boolean(conflictAnchorEl)}
          onClose={() => setConflictAnchorEl(null)}
          MenuListProps={{
            "aria-labelledby": "basic-button",
          }}
        >
          <MenuItem
            key="conflict"
            color="info"
            onClick={(e) => postEvent("conflict")}
            style={{ textTransform: "uppercase" }}
          >
            Internal Conflict
          </MenuItem>
          <MenuItem
            key="conflict"
            color="info"
            onClick={(e) => postEvent("client_conflict")}
            style={{ textTransform: "uppercase" }}
          >
            Client Conflict
          </MenuItem>
        </Menu>
      </span>

      {targetStage != "buyer" && (
        <Tooltip title="Support the Fallout Strategy">
          <Button
            variant="text"
            startIcon={<AccountBalanceIcon />}
            onClick={(e) => postEvent("buyer")}
            color="info"
            style={{ margin: "0 10px" }}
          >
            Buyer
          </Button>
        </Tooltip>
      )}

      {targetStage != "reject" && (
        <Button
          variant="text"
          startIcon={<RejectIcon />}
          onClick={(e) => postEvent("reject")}
          color="error"
          style={{ margin: "0 10px" }}
        >
          Reject
        </Button>
      )}

      {targetStage == "send" && (
        <Button
          variant="contained"
          startIcon={<RejectIcon />}
          onClick={(e) => postEvent("client_reject")}
          color="warning"
          style={{ margin: "0 10px" }}
        >
          Client Reject
        </Button>
      )}

      {["create", "land", "similar"].includes(targetStage) && (
        <Button
          variant="contained"
          endIcon={<AdvanceIcon />}
          onClick={(e) => postEvent("advance")}
          color="primary"
          style={{ borderRadius: "20px" }}
        >
          Review
        </Button>
      )}

      {targetStage == "advance" && (
        <Tooltip
          title={
            target["rating"] == null
              ? "Rating required to validate"
              : "Research Validated"
          }
        >
          <span>
            <Button
              variant="contained"
              endIcon={<ValidateIcon />}
              onClick={(e) => postEvent("validate")}
              color="primary"
              style={{ borderRadius: "20px" }}
              // disabled={target["rating"] == null}
            >
              Validate
            </Button>
          </span>
        </Tooltip>
      )}

      {targetStage == "validate" && (
        <Button
          variant="contained"
          endIcon={<SendIcon />}
          onClick={(e) => postEvent("send")}
          color="secondary"
          style={{ borderRadius: "20px" }}
        >
          Send
        </Button>
      )}

      {targetStage == "send" && (
        <Button
          variant="contained"
          endIcon={<CheckCircleIcon />}
          onClick={(e) => postEvent("client_approve")}
          color="primary"
          style={{ margin: "0 10px" }}
        >
          Client Approved
        </Button>
      )}
      {targetStage == "client_approve" && (
        <Button
          variant="contained"
          endIcon={<UploadIcon />}
          onClick={(e) => postEvent("sync")}
          color="success"
          style={{ borderRadius: "20px" }}
        >
          Sync
        </Button>
      )}
    </Box>
  );
}
